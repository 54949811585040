import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import CookieConsent from "react-cookie-consent";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: props.theme.secondaryText }}>
          Made with <span role="img">❤️</span> by {greeting.title2}
        </p>
      </Fade>
      <CookieConsent
        style={{ background: "#2B373B", zIndex: 998 }}
        buttonStyle={{
          background: "#fc1056",
          color: "#fff",
          fontSize: "13px",
          height: "auto",
          width: "auto",
        }}
        buttonClasses="button"
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}
